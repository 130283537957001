function App() {
  return (
    <div className="App">
       <header class="header">
          <h1>Welcome to <span>AgeGapDates!</span></h1>
          <p>Your journey to love starts here!</p>
      </header>
    </div>
  );
}

export default App;
